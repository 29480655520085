// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth

@use 'colors/colors' as var;

.mat-mdc-form-field {
  --mdc-filled-text-field-container-color: white;
}

.mat-select-small {
  .mdc-text-field--no-label:not(.mdc-text-field--outlined, .mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .mat-mdc-form-field-bottom-align {
    display: none;
  }
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: var.$wzb-light-blue !important;
  }

  .mdc-text-field--outlined {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    display: contents;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .mdc-text-field {
    border-radius: 8px;
  }

  .mdc-notched-outline__leading {
    border-radius: 8px 0 0 8px !important;
  }
  .mdc-notched-outline__trailing {
    border-radius: 0 8px 8px 0 !important;
  }

  .mat-mdc-select {
    font-size: 0.8125rem;
    font-weight: 500;
  }

  .mat-mdc-select-arrow {
    width: 0.625rem;
    height: 0.625rem;
    border-left: 0.125rem solid var.$wzb-light-blue;
    border-right: none !important;
    border-top: 0.125rem solid var.$wzb-light-blue;
    transform: rotate(-135deg);
    margin: 0 0 0.125rem 0.4375rem;

    svg {
      display: none;
    }
  }
}

.mat-mdc-select-panel {
  min-width: max-content !important;
}

.mat-mdc-option {
  font-size: 0.8125rem;
}

.filter-container {
  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    width: 4.6875rem;
  }
  .mat-mdc-text-field-wrapper {
    background: transparent;
    margin-bottom: -1.25em;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-select-value {
    max-width: 4.6875rem !important;
    margin-right: 0.4375rem;
  }

  mat-form-field {
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
  }

  .mat-mdc-select-placeholder {
    color: var.$wzb-black;
  }

  .mat-mdc-select-arrow {
    position: relative;
    width: 0.6563rem;
    height: 0.3906rem;
    display: inline-block;
    border: none;
    margin: 0;
    transition: transform ease-in-out 200ms;

    svg {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: black;
      border-radius: 1rem;
      display: block;
      top: -5%;
      height: 125%;
      width: 20%;
      transform: rotate(45deg);
      transition: background-color ease-in-out 200ms;

      &:hover {
        background-color: black;
      }
    }

    &::before {
      left: 60%;
    }

    &::after {
      right: 60%;
      transform: rotate(-45deg);
    }
  }

  .mat-mdc-form-field.mat-focused.mat-primary {
    .mat-mdc-select-arrow {
      transform: rotate(-180deg);
    }
  }
}

.filter-select-option {
  max-width: 17.5rem;
  font-weight: normal !important;
  font-size: 1rem !important;

  .mdc-list-item__primary-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-option-pseudo-checkbox {
    background-color: var.$grey-4 !important;
    border-radius: 6px !important;
    border-color: var.$grey-4 !important;
    width: 1.25rem !important;
    height: 1.25rem !important;

    &.mat-pseudo-checkbox-checked {
      border-color: var.$wzb-light-blue !important;
      background-color: var.$wzb-light-blue !important;
    }
  }
}
