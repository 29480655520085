.wzb-card {
  margin: 1.25rem 0;
  background: white;
  padding: 1.25rem;
  box-shadow: 0 0.9375rem 3.125rem rgb(0 0 0 / 10%);
  border-radius: 1.25rem;

  &__header {
    margin: 0 0 1.0625rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  &__body {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: normal;
  }

  &:first-child {
    margin-top: 0;
  }
}

.card {
  &__single-row,
  &__single-col {
    display: flex;
    margin: 1.0625rem 0 0;
    align-items: baseline;
  }

  &__label {
    font-weight: 600;
  }

  &__single-col {
    flex-direction: column;
  }

  &__icon {
    margin-right: 1.25rem;
    cursor: pointer;

    &--left {
      margin-right: 1rem;
      cursor: default;
    }

    &--large {
      height: 1.75rem !important;
    }
  }
}
