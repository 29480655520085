/* You can add global styles to this file, and also import other style files */
@use 'colors/colors' as var;
@use 'fonts/wzb-font' as font;
@use 'theming';
@use 'card';
@use 'sidemenu';
@use 'tag';
@use 'overrides';
@use 'button';
@use 'material/mat-select';
@use 'material/mat-dialog';
@use 'material/mat-expansion-panel';
@use 'override/bo-table';
@use 'banking-partner-color';

html {
  font-family: font.$wzb-global-font-family; // stylelint-disable-line font-family-name-quotes
}

* > * {
  box-sizing: border-box;
}

.focus-off *:focus {
  outline: none !important;
}

.page-unauthorized-component {
  position: absolute;
  inset: 0;
}

.migration-modale {
  position: relative !important;
}

.wzb-bo-navmenu__link {
  color: inherit;
}

app-dossier-card {
  .mat-form-field-infix {
    padding: 0.4375em 0;
  }
}

.wzb-suggestion-btn {
  color: white;
}

.wzb-navbar__link--active {
  color: var.$wzb-light-blue;
  background-color: var.$wzb-black;
  border-radius: 0.625rem;
}

.navmenu__option {
  flex-direction: column;
  gap: 1rem;
}
