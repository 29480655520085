@use 'colors/colors' as var;

.wzb-bo-menu {
  &__icon {
    background-color: white;
    color: var.$wzb-black;
    font-weight: bold;

    & path {
      fill: var.$wzb-black !important;
    }
  }

  &__title__texts {
    font-weight: 600 !important;
    transition: color 100ms ease-in-out;
  }

  .item {
    transition: font-weight 100ms ease-in-out;

    &::before {
      border: unset !important;
      background-color: var.$wzb-black !important;
      transition: background-color 100ms ease-in-out;
      margin-left: 0.625rem !important;
      margin-right: 1.375rem !important;
    }
  }

  .mat-expansion-panel-header {
    padding: 0 1.25rem;

    &:first-child {
      margin-top: 0.9375rem;
    }
  }

  .mat-expanded {
    .icon {
      background-color: var.$wzb-light-blue !important;

      & svg {
        height: 0.3125rem;
      }

      & path {
        fill: white !important;
      }
    }

    .title__texts {
      color: var.$wzb-light-blue !important;
      font-weight: 600;
    }

    .item--active {
      font-weight: 600;
      color: var.$wzb-light-blue !important;

      &::before {
        background-color: #21d378 !important;
        border: unset;
      }
    }
  }
}
