@use 'colors/colors' as var;

.btn-small {
  margin: 0 0 0 0.625rem;
  padding: 0.4375rem 0.625rem;
  border: 0.0625rem solid var.$wzb-light-blue;
  color: var.$wzb-black;
  background-color: transparent;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 0.8125rem;
  box-shadow: none;
  min-width: fit-content;

  &:hover {
    box-shadow: none;
  }
}
