:root {
  --boursorama-bg: #ff0084;
  --sg-bg: #e2010b;
  --bforbank-bg: #0128e2;
  --monabanq-bg: #bad606;
  --hype-bg: #163cff;
  --revolut-fr-bg: linear-gradient(to right, #9539f2, #6fa0ff);
  --revolut-it-bg: linear-gradient(to right, #6fa0ff, #9539f2);
  --helios-bg: #53e39b;
  --credit-agricole-it-bg: #008991;
}

.partner-bg {
  &--boursorama {
    background-color: var(--boursorama-bg);
  }

  &--sg {
    background-color: var(--sg-bg);
  }

  &--bforbank {
    background-color: var(--bforbank-bg);
  }

  &--monabanq {
    background-color: var(--monabanq-bg);
  }

  &--hype {
    background-color: var(--hype-bg);
  }

  &--revolut_fr {
    background: var(--revolut-fr-bg);
  }

  &--revolut_it {
    background: var(--revolut-it-bg);
  }

  &--helios {
    background-color: var(--helios-bg);
  }

  &--credit-agricole_it {
    background-color: var(--credit-agricole-it-bg);
  }
}

.partner-dot {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;

  &--boursorama {
    background-color: var(--boursorama-bg);
  }

  &--sg {
    background-color: var(--sg-bg);
  }

  &--bforbank {
    background-color: var(--bforbank-bg);
  }

  &--monabanq {
    background-color: var(--monabanq-bg);
  }

  &--hype {
    background-color: var(--hype-bg);
  }

  &--revolut_fr {
    background: var(--revolut-fr-bg);
  }

  &--revolut_it {
    background: var(--revolut-it-bg);
  }

  &--helios {
    background-color: var(--helios-bg);
  }

  &--credit-agricole_it {
    background-color: var(--credit-agricole-it-bg);
  }
}
