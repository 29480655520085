@use 'colors/colors' as var;

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth

mat-dialog-container {
  .mat-mdc-dialog-surface {
    border-radius: 1.25rem !important;
    padding: 1.25rem;
  }

  .mat-mdc-dialog-content {
    border-top: 0.0625rem solid var.$grey-4;
  }

  .label {
    color: var.$wzb-black;
  }

  mat-dialog-actions {
    margin: 1.25rem 0 0;
    padding: 0;
    min-height: unset;
    display: block !important;
  }
}
