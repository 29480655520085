@use 'colors/colors' as var;
@use 'sass:color';

.archived-table {
  .mat-header-row {
    background-color: color.scale(var.$wzb-light-blue, $alpha: -92%);

    .mat-header-cell {
      color: var.$wzb-light-blue;
    }
  }
}

.migration-main {
  .mat-mdc-header-row {
    background-color: var.$wzb-light-blue-15;
  }

  .migration-row {
    cursor: pointer;
  }

  .mat-column-ownerId {
    display: flex;
    justify-content: flex-end;
  }
}

.hotline-list {
  .mat-mdc-header-row {
    background-color: var.$wzb-light-blue-15;
  }

  .mat-mdc-cell {
    border-radius: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .hotline-row {
    cursor: pointer;
  }

  .mat-column-date {
    display: flex;
    justify-content: flex-end;
  }

  .mat-column-ownerId {
    display: flex;
    justify-content: center;
  }
}

.orders {
  .mat-mdc-header-row {
    background-color: var.$wzb-light-blue-15;
  }

  .mat-mdc-cell {
    border-radius: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .order-row {
    cursor: pointer;
  }
}
