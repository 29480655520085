@use '@wizbii-components/angular-styles/src/modules/wzb-theming/wzb-toggle' as wzbToggle;
@use '@wizbii-components/angular-styles/src/modules/wzb-theming/wzb-checkbox' as wzbCheckbox;
@use 'colors/colors' as var;
@use '@wizbii-components/bo-styles/src/material/bo-theming' as newBoTheming;

$md-blue: (
  50: #e5f7fd,
  100: #beebfb,
  200: #92def8,
  300: #66d1f5,
  400: #46c7f3,
  500: #25bdf1,
  600: #21b7ef,
  700: #1baeed,
  800: #16a6eb,
  900: #0d98e7,
  A100: #fff,
  A200: #dff2ff,
  A400: #acdeff,
  A700: #92d4ff,
  contrast: (
    50: #000028,
    100: #000028,
    200: #000028,
    300: #000028,
    400: #000028,
    500: #000028,
    600: #000028,
    700: #000028,
    800: #000028,
    900: #000028,
    A100: #000028,
    A200: #000028,
    A400: #000028,
    A700: #000028,
  ),
);
$md-black: (
  50: #e0e0e5,
  100: #b3b3bf,
  200: #808094,
  300: #4d4d69,
  400: #262648,
  500: #000028,
  600: #000024,
  700: #00001e,
  800: #000018,
  900: #00000f,
  A100: #5252ff,
  A200: #1f1fff,
  A400: #0000eb,
  A700: #0000d1,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
$md-invalid-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: white,
  A200: white,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

@include newBoTheming.boTheming($md-black, $md-blue, $md-invalid-red);
@include wzbToggle.wzbToggle(var.$wzb-light-blue, var.$wzb-black, #d8d8d8);
@include wzbCheckbox.wzbCheckbox(var.$wzb-light-blue, var.$wzb-black, #d8d8d8);
