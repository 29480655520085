// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth

.complementary-form-accordion {
  gap: 0.9375rem;
  display: flex;
  flex-direction: column;

  mat-expansion-panel {
    border: 1px solid #d1e1f5;
    box-shadow: none !important;
    border-radius: 0.625rem !important;
    .mat-expansion-indicator {
      display: block !important;
    }
  }

  mat-expansion-panel-header {
    margin: 0.875rem 1.25rem !important;
    padding: 0 !important;
    height: unset !important;
  }

  mat-panel-title {
    color: unset !important;
    margin: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-right: 0.9375rem !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.21 !important;

    mat-icon {
      color: inherit !important;
    }
  }

  .mat-expansion-panel-body {
    margin: 0 1.25rem !important;
    padding: 0 0 1.25rem !important;
    border-top: 1px solid #d1e1f5;
  }

  .wrap-content {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
}
